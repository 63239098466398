<template>
    <div>
        <base-form
            ref="form"
            v-model="form"
            :api="api"
            :mapper="mapper"
            :entity-id="entityId"
            :title="title"
            :disabled="disabled"
            :disable-delete-button="true"
            @created="created($event)"
            @updated="updated($event)"
            @valid="baseFormValid($event)"
        >
            <v-row class="pt-2 grey-bg">
                <v-col cols="12" sm="12" class="py-0">
                    <v-card elevation="0" class="mb-4">
                        <v-card-text>
                            <form-one-col-row>
                                <template>
                                    <text-input
                                        v-model="form.name"
                                        v-validate="
                                            'required|min:2|max:50|space'
                                        "
                                        name="name"
                                        :error-messages="errors.collect('name')"
                                        :data-vv-as="
                                            $t(
                                                'custom_export_category.category_name'
                                            )
                                        "
                                        :label="'custom_export_category.category_name'"
                                        autocomplete="off"
                                    ></text-input>
                                </template>
                            </form-one-col-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <portal to="footer">
                <v-btn
                    color="grey"
                    outlined
                    style="margin-right: 0.75rem"
                    @click="backtolist"
                >
                    {{ $t('actions.back-to-list') }}
                </v-btn>
            </portal>
        </base-form>
    </div>
</template>

<script>
import FormMixin from '@/components/mixins/FormMixin'
import ApiClientType from '@/api/RestApiType'
import MapperType from '@/services/mapper/MapperType'
import routeType from '@/router/routeType'
import Config from '@/config.loader'

export default {
    mixins: [FormMixin],
    data() {
        return {
            api: ApiClientType.CUSTOM_EXPORT_CATEGORY,
            mapper: MapperType.CUSTOM_EXPORT_CATEGORY_FORM,
            form: {},
            entity: 'custom_export_category.category_name',
            editRoute: routeType.CUSTOM_EXPORT_CATEGORY_LIST,
            listRoute: routeType.CUSTOM_EXPORT_CATEGORY_LIST,
            isValid: false,
            baseURL: Config.getConfigValue('VUE_APP_ADMIN_API_BASE'),
        }
    },
    computed: {
        title: function () {
            return this.$t('custom_export_category.title')
        },
    },
    methods: {
        baseFormValid(value) {
            this.isValid = value
        },
        backtolist() {
            this.$router.back()
        },
    },
}
</script>
